<template>
  <pre>
  <code v-html="code">
  </code>
  </pre>
</template>

<script>

export default {
  name: 'WebchatFront',
  components: {
  },
  created() {
  },
  data() {
    return {
      code: null,
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
body{
  background: #fff !important;
  padding: 10px;
}
</style>
